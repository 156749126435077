html, body {
  max-width: 100%;
  overflow-x: hidden;
  
}



video {
	width: 100vw;
	
}

.paperContainer {
            width: 100vw;
            height: 100vh;
            background-image:  url("../../Imagenes/foto3chicab.jpg");
            background-size: cover;
            background-color: transparent;   
            margin-bottom: -20px;
                    
}

.barraSuperior {
  position:fixed;
  top:-58px;
  width: auto;
  right: -2px;
  height: 6vh;
  z-index: 20;
  
  background-color:  rgb(100, 165, 120);
  box-shadow: 0 7px 6px -6px black;
  max-height: 68px;
}

.barraSuperior_app {
  position:fixed;
  top:-60px;
  width: auto;
  right: 0px;
  height: 6vh;
  z-index: 0;
  border-right: 2px solid #ddd;
  background-color:  rgb(100, 165, 120);
  box-shadow: 0 7px 6px -6px black;
  max-height: 68px;
}
.imagen_peq_barra {
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
}


.separacion{
  margin-left: 10px;
}


.paperContainerSinimagen {
  width: 100%;
  padding-top: 100px;
  margin-top: 30px;
  padding-bottom: 130px;
  background-color: rgb(246, 239, 223); 
        
}
.paperContainerSinimagen2 {
  width: 100%;
  padding-top: 0px;
  margin-top: -15px;
  margin-bottom: -150px;
  background-color:white;   
  padding-bottom: 100px;        
}

.paperContainerSinimagen3 {
  margin-top: -15px;
  margin-top: 150px;
  background-color:rgb(255, 242, 250);   
  padding-bottom: 200px;            
}
.paperContainerSinimagen4 {
  margin-top: -15px;
  margin-top: 150px;
  padding-bottom: 200px;
  background-color:rgb(250, 242, 226);   
    
  margin-bottom: -150px;             
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++ventana 1 */

.foto_app{
margin-top: -60px;
    width: 100vw;
    height:auto;
    min-height: 90vh;
    max-height: 100vh;
    position:fixed;
    z-index: -1;
  }



  .papel2 {     

    z-index: -1;
  display:flex;
  height: 80vh;
  width: 100vw;
  background-color:transparent;
 
 }

 .papel_principal {     
  position:absolute;
  z-index: 9;
  bottom: -15px;
}
.papel {     
  z-index: 11;
   background-color: rgb(58, 107, 36);
   border-top: 2px solid #ddd;
   border-left: 2px solid #ddd;
   border-right: 2px solid #ddd;
   border-top-left-radius: 54px;
   border-top-right-radius: 54px;
    max-width: 100vw;  
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
   max-height: 120px;
   color: white;

}

.titulo_grande_app{
  
  padding: 13px;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:22px;
}


.titulo_mediano_app {
  
 
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:15px;

}

.cuad_ini{
  
  background-color: rgb(29, 68, 29);
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:15px;
  color:rgb(241, 189, 20);
  height: 33px;
  border-left: 2px solid #ddd;
   border-right: 2px solid #ddd;
   text-align: center;
  margin-top: -1px;
  width:99vw;

}
/*FIN VENTANA 1 ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
.papel_espacios_entre {
  
  padding-top: 60px;
  padding-left: 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;

}

.papel_espacios_entre2 {
  
  padding-top: 20px;
  padding-left: 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;

}

.papel_espacios_entre2_app {
  
  padding-top: 30px;

  display: flex;
  flex-direction: row;

}
.papel_espacios_entre3 {
 
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;

}
.papel_espacios_entre3-peque {
 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;

}
.papel_espacios_entre_app {
  
  padding-top: 30px;

  display: flex;
  flex-direction: row;

}

.papel_ventanas{
    margin:10vh;
}
.papel_contratacion{
  margin-top:5vh; 
  margin-left: 12vh;
  margin-right:  12vh;
}

.papel_ventanas_app{
  margin:2vh;
}

.papel_espacio_ventanas{
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;

}

.negrita{   
  color: rgba(9, 3, 77);
   font-weight: bold;}

.img {       
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 2px;
  width: 15%;
  min-width:20;
  min-height: 20;
  height: 15vh;
}

.imagen {

  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:7vh;
  
  margin-left: 25%;
  margin-top: 10%;
}


.imagen2 {
  position:absolute;
  font-size:3vh;
  margin-left: 85%;
  margin-top: 85vh;
  font-weight: bold;


}


.imagen_peq {
  position:absolute;

  font-size:5vh;

  margin-top: -20%;
  margin-left:6%;
}

.imagen5{
  
  height: 75vh; width: 31vw;
 
  
}

.imagen52{
  
  width: 100%;
  height: 100%;
 
}

.a {
  outline: none;
   text-decoration: none;

   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:2.7vh;
   border-radius: 10px;
   padding: 1px 14px 0;
   color: rgb(252, 252, 253);
   border: 2px solid #ddd;
 

  background-color:rgba(248, 163, 4, 0.711);
  
 }

 .a:hover {
 
  border-bottom: 6px solid #ddd;
  background-color: rgba(248, 163, 4);
 
}
.a:active {
  border-bottom: 6px solid #ddd;
  background-color:  rgba(248, 163, 4);
  opacity: 0.3;
}


.b {
  outline: none;
   text-decoration: none;

   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:8vh;
   border-radius: 10px;
   padding: 1px 14px 0;
   color: rgb(252, 252, 253);
   border: 2px solid #ddd;
 

  background-color:rgba(248, 163, 4, 0.6);
  
 }

 .b:hover {
 
  border-bottom: 6px solid #ddd;
  background-color: rgba(248, 163, 4);
 
}
.b:active {
  border-bottom: 6px solid #ddd;
  background-color:  rgba(248, 163, 4);
  opacity: 0.3;
}
      
.titulo {
        position:absolute;
        font-family:Verdana,Helvetica,Arial,sans-serif;
        width: 30%;
        top:5%;
        right: 2%;
        color: rgba(9, 3, 77);
 
  }
  .cuadro_portada{
  
    border-radius: 18px;
    padding-left: 8px;
    padding-right: 8px;
     background-color:rgba(96, 163, 116, 0.7);
    
  }
.titulo2 {
    text-align: center;
   
    font-family:Verdana,Helvetica,Arial,sans-serif;
   
    
    color: rgba(9, 3, 77, 0.877);

}
.titulo2_app {
  text-align: center;
 
  font-family:Verdana,Helvetica,Arial,sans-serif;

  padding-top: 40px;
  color: rgba(9, 3, 77, 0.877);

}

.titulo3 {
  text-align:left;
 
  font-family:Verdana,Helvetica,Arial,sans-serif;

  padding-top: 120px;
 
  
 
  color: rgba(9, 3, 77, 0.877);

}


  .titulo_grande{
    
   font-weight: bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:5vh;
  }
 




.titulo_grande2{
 
  font-weight: bold;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:5vh;
}

.titulo_grande2_app{

 
  font-weight: bold;

  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:3.5vh;
}


 
.alineamiento_titulo_contratacion{
  max-width: 45vw;
  text-align: start;
}
.alineamiento_titulo_contratacion_app{
  max-width: 100%;
  text-align: start;
}


  .alineamiento_titulo{
    max-width: 18vw;
    text-align: start;
  }
  .alineamiento_titulo_app{
    max-width:45vw;
    margin-left: 4vw;
    text-align: start;
  }

  
  .titulo_mediano{
    
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.7vh;
  }
  .titulo_mediano_bold{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.7vh;
  }
  .titulo_mediano_bold_contratacion{
    margin-top:-0.5vh ;
    margin-bottom:1vh ;
    font-weight:bold;
    color: green;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:3vh;
  }
  .titulo_mediano_claro{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.7vh;
    color: rgba(9, 3, 77, 0.6);
  }


  .titulo_mediano_icono{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.1vh;
    color: rgba(9, 3, 77, 0.6);
  
  }


  .titulo_mediano-peque{
   
    font-weight: bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.5vh;
  }

  .titulo_peque_bold{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.5vh;
  }

  .titulo_peque_bold_contratacion{
    
    font-weight:bold;
    color: green;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.2vh;
  }
  .titulo_peque{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.5vh;
  }
  .titulo_peque_claro{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2.2vh;
    color: rgba(9, 3, 77, 0.6);
  }

  .titulo_peque_claro_app{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2vh;
    color: rgba(9, 3, 77, 0.6);
  }

  .titulo_peque_bold_app{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:2vh;
    max-width: 80vw;
  }

/* Dialogo3 ++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

 .tarjetas{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  
 }

 .tarjetas_app{
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  
 }

 .tarjeta_app{
  min-width:10vw;
  width: 100vw,
 }
 .tarjeta{
  min-width:10vw;
  width: 10vw,
 }

 .tarjeta_media_app{
  height: 180px;
  width: 100%;
 padding-top: '56.25%'

 }
 
 .tarjeta_media{
  height: 38vh;
  width: 32vw;
 padding-top: '56.25%'

 }
 

  .img {
  
  
    height: 55vh; width: 55vw;
    }


.eframe{
  
  height: 55vh; width: 45vw;
 
  
}

.eframe2{
  
  width: 95%;
  height: 100%;
 
}


.eframe3{
  

  width: 95%;
  min-height:40vh;
  
  
  
}

.img3{
  width: 15vw;
  height: 15vh;
  background-color: transparent;
 
}

.img4{
  width: 100px;
  height: 80px;
  background-color: transparent;
 
}

.otros{
 
  color:black;
  font-size: 5vh;
}
.otros2{
 
  color:black;
  font-size: 2.5vh;
}



/*Ventana pantalla 5 Valores ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.videoL{
  width: 100%;
  height: auto;
 
}
.paperContainerSinimagen5 {
 
  padding-top: 40px;     
  background-color: rgb(255, 255, 255);   
  padding-bottom: 100px;
  margin-bottom: -150px;
}


.posicion{
  position:absolute;
  margin-top: 84px;
  margin-left: 32vw;
  


 }


.wrapper4 {
  background-color:transparent;
  height: 35vh;
  display: flex;
}


.cuadro-mapa{
    height: 200px;
    max-width: 320px;
    
    margin-right:20px;
    text-align: center;
    background-color:transparent;
    border:6px solid rgb(255, 255, 255);
    border-radius: 5px;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:22px;
    color: rgb(255, 255, 255);
   
   }
   
   .texto-cuadro{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:22px;
    color: rgb(255, 255, 255);

   }
   .cuadro-titulo{

    position:absolute;
    margin-top: 380px;
    margin-left: 2vw;
    font-weight:bold;
    width: 40vh;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:30px;
    color: rgb(255, 255, 255);


   }


   .valores_app{
    
    margin-top: -15vh;
      width: 100vw;
      height: 100vh;
    }
    .posicion_app{

      position:absolute;
      
     
      width: 97%;
      align-items: center;
      
     
    
     }
    
     .wrapper4_app {
      display: flex;
      margin-left: 4vw;
      
      flex-direction: column;
      justify-content:space-evenly;
      background-color:transparent;
      width: 94%;
      max-height: 210px;
      
    }

   .cuadro-mapa_app{
    max-height: 12vh;
  
    margin-left: 1px;
    margin-top: 10px;
    text-align: center;
    background-color:transparent;
    border:3px solid rgb(255, 255, 255);
    border-radius: 5px;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:18px;
    color: rgb(255, 255, 255);
   
   }
   
   .texto-cuadro_app{
    font-weight:bold;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:18px;
    color: rgb(255, 255, 255);

   }
  
   .cuadro-titulo_app{

    margin-top: -12px;
    font-weight:bold;
    height: 16vh;
    text-align: center;
    font-family:Verdana,Helvetica,Arial,sans-serif;
    font-size:3vh;
    color: rgb(255, 255, 255);
    margin-bottom: -5px;


   }

   /**VENTANA 6  Mendio Ambiente ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .paperContainerSinimagen6 {
    margin-top: 100px;
    padding-top: 115px; 
    padding-bottom: 100px;   
    background-color: rgb(255, 255, 255);
  }
.paperContainer2_parallax {
    width: 100%;
            
    background-image:  url("../../Imagenes/arbol3.jpg");
    height:70vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-1px) scale(1.5);
                    
}
.paperContainer3_parallax {

          
  background-image:  url("../../Imagenes/arbol3_p.jpg");
  
  width: 100vw;
height: 70vh;

background-size: cover;
background-attachment:local;
background-position: center, center;
background-repeat: no-repeat;

transform: translateY(-1px) scale(1.5);
                  
                  
}

.titulo_2 {
  position:absolute;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  width: 30%;
  top:30%;
  left: 35%;
  color: rgb(251, 251, 251);
  text-align: center;

}

.titulo_grande_2{
    
  font-weight: bold;
   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:4vh;
 }
 .titulo_mediano_2{
   
   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:2vh;
 }

 .titulo_2_app {
  position:absolute;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  width: 50%;
  top:25%;
  left: 25vw;
  color: rgb(251, 251, 251);
  text-align: center;

}

.titulo_grande_2_app{
    
  font-weight: bold;
   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:2.5vh;
 }
 .titulo_mediano_2_app{
   
   font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size:1.5vh;
 }


 /*++++++++Ventana 7 EQUIPO+++++++++++++++++++++++++++++++++++++++++++*/

 .paperContainerSinimagen7 {
  width: 100%;
  padding-top: 150px;
 
  padding-bottom: 160px;
 
  background-color: rgb(255, 255, 255); 
        

}

.titulo_grande_equipo{
  margin-right: 200px;
  text-align: end;
  font-weight: bold;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:5vh;
}

.titulo_grande_equipo_app{
  margin-right: 10px;
  text-align: center;
  font-weight: bold;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:3vh;
}

.titulo_mediano_icono_7{
  font-weight:bold;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:2.1vh;
  color: rgba(9, 3, 77, 0.6);

}

.alineamiento_titulo_7{
  max-width: 40vw;
  text-align: start;
}

.alineamiento_titulo_7_app{
  margin-left: 10px;
  max-width: 95vw;
  text-align: start;
}

.foto_avatar{
  margin: 8px;
  width: 80px; 
  border-radius: 50px;
  box-shadow: 0 7px 6px -6px black;

}

.cuadro_trasero{
  width:  100%;
  background-color: rgba(39, 151, 39, 0.367);
  box-shadow: 0 7px 6px -6px black;
}


 /*++++++++Ventana 8 Actualidad+++++++++++++++++++++++++++++++++++++++++++*/

 .paperContainerSinimagen8 {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 160px;
  background-color: rgb(250, 247, 239); 
}

.fotos_actualidad{
width: auto; 

}

.imagen6{
  margin-left: 20px; 
  height: 37vh; width: auto;
  box-shadow: 7px  7px 6px -6px black;
  
}
.imagen6_app{
 
  height: 30vh; 
  width: 94vw;
  box-shadow: 7px  7px 6px -6px black;
  
}

.imagen_partner{
  margin-left: 0px; 
  background-color: rgb(255, 255, 255);
  height: 13vh; 
  width:15vw;
  box-shadow: 7px  10px 9px -5px black;

}

.imagen_partner_app{
  margin-left: 0px; 
  background-color: rgb(255, 255, 255);
  height: 12vh; 
  width:41vw;
  box-shadow: 7px  10px 9px -5px black;

}

.separacionH{
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;

}
.separacionH2{
  padding-top: 7vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content:space-between;

}

.separacionV{
  margin-top: -30px;
  min-height: 60vh;
  display: flex;
  min-width: 40vw;
  flex-direction: row;
  justify-content:space-evenly;

  
}
/*Dialogo registro+++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.dialogTitle{
  
  color: green;
 text-align: center;
 font-family:Verdana,Helvetica,Arial,sans-serif;
 font-size: 16px;
  
} 
 .dialogTitle2{
  text-align: center;
  font-family:Verdana,Helvetica,Arial,sans-serif;
   font-size: 25px;
   padding: 10px;

  font-weight: bold;
  color: green;
 } 

 .modal{
   background-color: rgb(255, 253, 253);
   text-align: center;
   display: 'flex';
   align-items: 'center';
   justify-content: 'center';
 }

 .cuadro_input{
 
  width: 40vw;
  height:auto;
  font-family:Verdana,Helvetica,Arial,sans-serif;
 }
 
 .radiogroup{
  display: flex;
  flex-direction: row;
  justify-content:space-around;

 }
 .espacios_entre {
  display: flex;
  flex-direction: row;
  justify-content:space-between;

}

.espacios_entre_col {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  padding-top: 20px;

}

.cuadro_input2{
  padding: 20px;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  height: auto;
 }

