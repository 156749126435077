.titulo_app{
  font-size: 2ch;
  font-weight: bold;
  color: white;
  width: auto;
  padding: 1px;
  background-color: rgb(30, 119, 70);
  font-family:Verdana,Helvetica,Arial,sans-serif;
  box-shadow: 0 7px 6px -6px black;
  border-radius: 4px;
}


.sidebar-top {
  max-width: 100%;
  margin-top: -0px;
  z-index: 20;
  background-color:rgb(29, 68, 29);
  box-shadow: 0 7px 6px -6px black;
  max-height: 65px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;

  .sidebar-links {
    z-index: 100;
    display: flex;
    align-items:center;
    justify-content: space-between;
 
  }

  .sidebar-link,
 

  .bars {
    font-size: 1px;
    color: white;
    width: auto;
    border: none;
  }
}

.sidebar-link.bars .sidebar-link > a {
  z-index: 100;
  text-decoration: none;
  color: #f6eafb;
 
}

.sidebar-link {
  z-index: 100;
  list-style: none;
  width: auto;
  border-top: 1px solid white;
  
}

.visible-enter {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: translate(-100%);
  background:rgb(29, 68, 29);
  margin-top: -15px;
  min-height: 20vh;
  max-width: 60vw;
 
  position: absolute;

  .sidebar-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }

  a.active {
    color: rgb(29, 68, 29);
  }
}

.visible-enter-active {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: translate(0);
  transition: transform 0.3s linear;
  margin-top: -15px;
  min-height: 20vh;
  position: absolute;
  max-width: 60vw;

  .sidebar-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }

  a.active {
    color:rgb(29, 68, 29);
  }
}

.visible-enter-done {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background:rgb(29, 68, 29);
  position: absolute;
  margin-top: -15px;
  min-height: 20vh;
  max-width: 60vw;
  .sidebar-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }

  a.active {
    color:rgb(29, 68, 29);
  }
}

.visible-exit {
  z-index: 100;
  transform: translate(0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background:rgb(29, 68, 29);
  position: fixed;
  margin-top: -15px;
  min-height: 20vh;
  max-width: 60vw;
  .sidebar-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }
  a.active {
    color:rgb(29, 68, 29);
  }
}

.visible-exit-active {
  z-index: 100;
  transform: translate(-100%);
  transition: transform 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: rgb(29, 68, 29);
  position: fixed;
  margin-top: -15px;
  min-height: 20vh;
  max-width: 60vw;

  .sidebar-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a.active {
    color: rgb(29, 68, 29);
  }
}
.cuad-img54{

  padding-top: 6px;
}

.img54{
  background-color: rgb(255, 255, 255);
  opacity: 0.7;
  width: 100px;
  
 margin:-12px 20px 0 0px;
}




.barraSuperior {
  position:absolute;
  top:1px;
  width: 99%;
  height: 6vh;

  box-shadow: 0 7px 6px -6px black;
  max-height: 68px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;


}