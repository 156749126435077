.contact-list-table {
  padding: 2rem 0rem;

  .table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-color: grey;

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      text-align: inherit;
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr:first-child {
        td {
          border-top: 0;
        }
      }
    } 

    th {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
      text-align: inherit;
    }

    td {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
  }
}