.navbar-wrapper {
  position:absolute;
  top: 90%;
  width: 100%;
    font-size: 2.5vh;
    z-index: 6;
    
}
.navbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    max-height: 9vh;
    background-color: rgb(29, 68, 29);
    box-shadow: 0 7px 6px -6px black;
  
    .navbar-top-links {
      display: flex;
      margin-right: 10px;
    }
  }
  
.navbar-link,
  
  
.navbar-link > a {
    text-decoration: none;
    color: #f6eafb;
}
  
a.active {
    color: rgb(100, 165, 120);
}
  
.header {
   // margin-top: -25px;
     background:transparent;
    
   }
 
   .sticky {
   
     position: fixed;
     margin-top: -22px;
     top:0;
     width: 100%;
   }
   
   .button {
    background-color: transparent;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .button:hover {
 
    border-bottom: 1px solid #ddd;
    background-color: rgb(201, 212, 212);
    opacity: 0.3;
  }
  .button:active {
    border-bottom: 6px solid #ddd;
    background-color: rgb(22, 153, 153);
    opacity: 1;
  }


  .img2{
    background-color: rgb(255, 255, 255);
    opacity: 0.7;
    width: 6.5vw;
    height: auto;
  
    margin-top:7px 
   
  }


  .wrapper {
 
    max-width: 80vw;
    overflow-x: scroll;
  
    overflow-y: hidden;
    flex-direction: row;
    
    
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-gap: 0 1px;
    
    
     }

  .wrapper::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }