.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .content {
    font-size: 16px;
    font-weight: bold;
    background: green;
    color:white;
  }
  

.listaDocumentos {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content:space-around;
  
    }
