
.imagen{
  font-weight: bold;
  margin-top: 5px;
  margin-left: 0px;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:15px;
  color:green;
 
}
.listaFicheros{

 max-width: 50vw;
}
