.deal-form {
  background-color: white;
  padding: 2rem 2rem;
  width: 30vw;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  h2 {
    margin-block-start: 0;
  }

  .title {
    margin: 10px 0;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }
}